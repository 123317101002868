import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'popper.js';
import '../styles/images.scss';
import '../styles/index.scss';
import '../scripts/app';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}
