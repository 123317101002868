'use strict'

let metaData = require('../data/content.json');
let locale = undefined;
let tab = undefined;

$(document).ready(() => {
    locale = $("body").data("locale");

    getActiveBody();
    events();
    translateMenu();
    removeOverlayAfterLoad();
});

function removeOverlayAfterLoad() {
    let overlay = document.getElementById('overlay');
    if (!overlay) { return; }
    $(overlay).fadeOut(600, () => {
        $(overlay).remove();
    });
}

function translateMenu() {
    if (locale === 'ja') {
        $('#header-home')[0].innerText = 'ホーム';
        $('#header-skills')[0].innerText = 'スキル';
        $('#header-contact')[0].innerText = 'Contact Us';
        $('#footer-home')[0].innerText = 'ホーム';
        $('#footer-skills')[0].innerText = 'スキル';
        $('#footer-contact')[0].innerText = 'Contact Us';
    } else {
        $('#header-home')[0].innerText = 'Home';
        $('#header-skills')[0].innerText = 'Skills';
        $('#header-contact')[0].innerText = 'Contact Us';
        $('#footer-home')[0].innerText = 'Home';
        $('#footer-skills')[0].innerText = 'Skills';
        $('#footer-contact')[0].innerText = 'Contact Us';
    }
}

function events() {
    $('#brand-home').click(()=>{
        $('#header-home').parent().addClass("active");
        $('#header-skills').parent().removeClass("active");
        tab = 'home';
        getActiveBody();
    });
    $('#header-home').click(() => {
        $('#header-home').parent().addClass("active");
        $('#header-skills').parent().removeClass("active");
        tab = 'home';
        getActiveBody();
    });
    $('#header-skills').click(() => {
        $('#header-skills').parent().addClass("active");
        $('#header-home').parent().removeClass("active");
        tab = 'skills';
        getActiveBody();
    });
    $('#header-contact').click(() => {
        toggleContactForm();
    });
    $('#header-about').click(() => {
        toggleAbout();
    });

    $('#footer-home').click(() => {
        $('#header-home').parent().addClass("active");
        $('#header-skills').parent().removeClass("active");
        tab = 'home';
        getActiveBody();
    });
    $('#footer-skills').click(() => {
        $('#header-skills').parent().addClass("active");
        $('#header-home').parent().removeClass("active");
        tab = 'skills';
        getActiveBody();
    });
    $('#footer-contact').click(() => {
        toggleContactForm();
    });
    $('#footer-about').click(() => {
        toggleAbout();
    });
    
    $('#closecontact').click(() => {
        toggleContactForm();
    });
    $('#closeabout').click(() => {
        toggleAbout();
    });
}

function getData (defLn = 'en') {
    return metaData[defLn];
}

function createElement({type, classlist= [], text= null, id= null, data= null}) {
    let element = document.createElement(type);
    if (id) {
        element.setAttribute('id', id);
    }
    if (classlist.length) {
        element.classList.add(...classlist);
    }
    if (text) {
        element.innerText = text;
    }
    if (data) {
        element.setAttribute('data-type', data);
    }
    return element;
}

function toggleContactForm() {
    let contactform = document.getElementById("contactform");
    let classList = contactform.classList["value"];
    if (classList.match(/active/)) {
        contactform.classList.remove("active");
    } else {
        contactform.classList.add("active");
    }
}

function toggleAbout() {
    let about = document.getElementById("about");
    let classList = about.classList["value"];
    if (classList.match(/active/)) {
        about.classList.remove("active");
    } else {
        about.classList.add("active");
    }
}

function bannerSection({carosal, bullets}) {
    let bannerContainer = createElement({type : "section", classlist: ["max"]});
    let bannerId = createElement({type :"div", classlist: ["banner"], id: "banner"});
    let carosalList = carosal.map(item => {
        return `<li>${item}</li>`;
    }) || [];
    let bulletsList = bullets.map(item => {
        return `<li>${item}</li>`;
    }) || [];
    let bannerHtml = createElement({type: "div", classlist: ["carosal"]});
    let bannerImg = createElement({type: "div", classlist: ["img", "banner-img"]});
    let bannerHgt = window.innerHeight - 140 + 'px';
    bannerHtml.innerHTML = `<ul class="main">${carosalList.join(" ")}</ul>` +
        `<ul class="bullets">${bulletsList.join(" ")}</ul>`;
    bannerId.style.minHeight = bannerHgt;
    bannerId.append(bannerHtml);
    bannerId.append(bannerImg);
    bannerContainer.appendChild(bannerId);
    return bannerContainer;
}

function descSection({pre, post}) {
    let descContainer = createElement({type: "section", classlist: ["description", "fullwidth"]});
    let description = createElement({type: "section", classlist: ["max"], id: "description"});
    let preContent = createElement({type: "div", classlist: ["pre"], text: pre});
    let postContent = createElement({type: "div", classlist: ["post"], text: post});
    description.appendChild(preContent);
    description.appendChild(postContent);
    descContainer.appendChild(description);
    return descContainer;
}

function skillsDescSection({desc}) {
    let descContainer = createElement({type: "section", classlist: ["description", "fullwidth"]});
    let description = createElement({type: "section", classlist: ["max"], id: "description"});
    let descContent = createElement({type: "div", classlist: ["desc"], text: desc});
    description.appendChild(descContent);
    descContainer.appendChild(description);
    return descContainer;
}

function serviceSection({title, data}) {
    let serviceContainer = createElement({type: "section", classlist: ["max", "fullwidth", "lightblue"]});
    let servicesId = createElement({type: "section", classlist: ["max"], id: "services"});
    let serviceBlock = createElement({type: "div", classlist: ["services"]});
    let serviceTitle = createElement({type: "div", classlist: ["title"]});
    serviceTitle.innerHTML = `<span>${title}</span>`;
    let serviceUl = createElement({type: "ul"});
    data.forEach(item => {
        let img = createElement({type: "span", classlist: ["img", item.classname]});
        let titleElm = createElement({type: "h3", classlist: ["title"], text: item.title});
        let liElm = createElement({type: "li", classlist: ["item", item.classname]});
        liElm.appendChild(img);
        liElm.appendChild(titleElm);
        serviceUl.appendChild(liElm);
    });
    serviceBlock.appendChild(serviceTitle)
    serviceBlock.appendChild(serviceUl);
    servicesId.append(serviceBlock);
    serviceContainer.appendChild(servicesId);
    return serviceContainer;
}

function expSection({data, title}) {
    let experienceId = createElement({type: "section", classlist: ["max"], id: "experience"});
    let experienceContainer = createElement({type: "div", classlist: ["experience"]});
    let experienceTitle = createElement({type: "div", classlist: ["title"], text: title});
    let experienceContent = createElement({type: "ul"});
    data.forEach(item => {
        let liElt = createElement({type: "li", classlist: ["item"], text: item});
        experienceContent.appendChild(liElt);
    });
    experienceContainer.appendChild(experienceTitle);
    experienceContainer.appendChild(experienceContent);
    experienceId.appendChild(experienceContainer);
    return experienceId;
}

function skillsSection({pre, post}) {
    let skillContainer = createElement({type: "section", classlist: ["max"]});
    let skillsId = createElement({type: "div", classlist: ["skills"]});
    let skillsSpan = createElement({type: "span", id: "skills"});
    let skillsPreTitle = createElement({type: "span", text: pre});
    let skillsPostTitle = createElement({type: "span", classlist: ["bold"], text: post});
    let skillsImg = createElement({type: "span", classlist: ["img", "skills"]});
    skillsSpan.append(skillsPreTitle);
    skillsSpan.append(skillsPostTitle);
    skillsSpan.append(skillsImg);
    skillsPreTitle.addEventListener('click', ()=> {
        $('#header-skills').parent().addClass("active");
        $('#header-home').parent().removeClass("active");
        tab = 'skills';
        getActiveBody();
    });
    skillsPostTitle.addEventListener('click', ()=> {
        $('#header-skills').parent().addClass("active");
        $('#header-home').parent().removeClass("active");
        tab = 'skills';
        getActiveBody();
    });
    skillsImg.addEventListener('click', ()=> {
        $('#header-skills').parent().addClass("active");
        $('#header-home').parent().removeClass("active");
        tab = 'skills';
        getActiveBody();
    });
    skillsId.append(skillsSpan);
    skillContainer.appendChild(skillsId);
    return skillContainer;
}

function whyusSection(whyus) {
    let whyusContainer =  createElement({type: "section", classlist: ["max", "fullwidth", "lightblue"]});
    let whyusId = createElement({type:"section", classlist: ["max"], id: "whyus"});
    let whyusBlock = createElement({type: "div", classlist: ["whyus"]});
    let whyusTitle = createElement({type: "div", classlist: ["title"], text: whyus["title"]});
    let whyusDesc = createElement({type: "p", classlist: ["desc"], text: whyus["desc"]});
    let whyusUl = createElement({type: "ul"});
    whyus["data"].forEach(item => {
        let li = createElement({type: "li", classlist: ["item"]});
        let img = createElement({type: "span", classlist: ["img", item.classname]});
        let desc = createElement({type: "span", classlist: ["desc"]});
        let title = createElement({type: "h4", classlist: ["title"], text: item.title});
        let content = createElement({type: "p", classlist: ["content"], text: item.content});
        desc.appendChild(title);
        desc.appendChild(content);
        li.appendChild(img);
        li.appendChild(desc);
        whyusUl.appendChild(li);
    });
    whyusBlock.appendChild(whyusUl);
    whyusId.appendChild(whyusTitle);
    whyusId.appendChild(whyusDesc);
    whyusId.appendChild(whyusBlock);
    whyusContainer.appendChild(whyusId);
    return whyusContainer;
}

function filterSkills(item) {
    let type = item.target.textContent;
    let filterBlock = document.getElementById('filter-block');
    let skillsBlock = document.getElementById('skills-block');
    if (filterBlock) {
        filterBlock.childNodes.forEach (elm => {
            if (elm.innerText === type) {
                elm.classList.add('active');
            } else {
                elm.classList.remove('active');
            }
        });
    }
    if (skillsBlock) {
        skillsBlock.childNodes.forEach (elm => {
            let attr = elm.getAttribute('data-type');
            if (attr === type || type === 'All Skills' || type === 'すべてのスキル') {
                elm.style.display = 'inline-block';
            } else {
                elm.style.display = 'none';
            }
        });
    }
}

function skillsBanner({pre, content, post, bullets}) {
    let bannerContainer = createElement({type : "section", classlist: ["max"]});
    let bannerId = createElement({type :"div", classlist: ["banner"], id: "banner"});
    let bannerHtml = createElement({type: "div", classlist: ["carosal"]});
    let bannerImg = createElement({type: "div", classlist: ["skill-img", "banner-img"]});
    let bannerHgt = window.innerHeight - 140 + 'px';
    let bulletsList = bullets.map(item => {
        return `<li>${item}</li>`;
    }) || [];
    bannerHtml.innerHTML = `<span class="main">${pre} <b>${content}</b> ${post}</span>` +
        `<ul class="bullets">${bulletsList.join(" ")}</ul>`;
    bannerId.style.minHeight = bannerHgt;
    bannerId.append(bannerHtml);
    bannerId.append(bannerImg);
    bannerContainer.appendChild(bannerId);
    return bannerContainer;
}

function allSkillBody({allSkills}) {
    let skillsContainer =  createElement({type: "section", classlist: ["max", 'all-skills']});
    let whyusTitle = createElement({type: "div", classlist: ["title"], text: allSkills['title']});
    let filterBlock = createElement({type: 'ul', classlist: ['tabs'], id: 'filter-block'});
    Object.keys(allSkills['skills']).forEach((key, idx) => {
        let classlist = ["item"];
        let { type } = allSkills['skills'][key];
        if (!idx) {
            classlist.push("active");
        }
        let li = createElement({type: "li", classlist, text: type});
        li.addEventListener('click', (type) => {
            filterSkills(type);
        })
        filterBlock.appendChild(li);

    });
    let skillsBlock = createElement({type: 'ul', classlist: ['images'], id: 'skills-block'});
    Object.keys(allSkills['skills']).forEach(key => {
        let { items, type } = allSkills['skills'][key];
        items.forEach(item => {
            let li = createElement({type: "li", classlist: ["item"], data: type});
            let title = createElement({type: 'span', classlist: ['title'], text: item['name']});
            let imgContainer = createElement({type: 'span', classlist: ['skill-img']});
            let img = document.createElement('img');
            img.setAttribute('src', item['img']);
            imgContainer.appendChild(img);
            li.appendChild(imgContainer);
            li.appendChild(title);
            skillsBlock.appendChild(li);
        });
    });

    skillsContainer.appendChild(whyusTitle);
    skillsContainer.appendChild(filterBlock);
    skillsContainer.appendChild(skillsBlock);
    return skillsContainer;
}

function contactSection({link, pre, post, brand}) {
    let contactContainer = createElement({type: 'section', classlist: ['max'], id: 'contact'});
    let title = createElement({type: "div", classlist: ["title"]});
    let contactLink = createElement({type: 'span', classlist: ['contact'], id: 'contact-bottom', text: link});
    contactLink.addEventListener('click', () => {
        toggleContactForm();
    });
    title.innerHTML = pre.length 
        ? `${pre}<b> ${brand} </b>${post}`
        : `<b>${brand} </b>${post}`;
    contactContainer.appendChild(title);
    contactContainer.appendChild(contactLink);
    return contactContainer;
}

function getHomePage(data) {
    let root = document.getElementById('root');
    let banner = bannerSection(data['banner']);
    let description = descSection(data['desc']);
    let service = serviceSection(data['services']);
    let experience = expSection(data['experience']);
    let skills = skillsSection(data['skills']);
    let whyUs = whyusSection(data['whyus']);
    let contact = contactSection(data["contact"]);

    root.innerHTML = ``;
    root.append(banner);
    root.append(description);
    root.append(service);
    root.append(experience);
    root.append(skills);
    root.append(whyUs);
    root.append(contact);
}

function getSkillsPage(data) {
    let root = document.getElementById('root');
    let banner = skillsBanner(data['skillsBanner']);
    let description = skillsDescSection(data['skillsBanner']);
    let allSkill = allSkillBody(data);
    let contact = contactSection(data["contact"]);
    root.innerHTML = ``;
    root.append(banner);
    root.append(description);
    root.append(allSkill);
    root.append(contact);
}

function getActiveBody() {
    window.scrollTo(0, 0);
    let data = getData(locale || 'en');
    if (tab !== 'skills') {
        getHomePage(data);
    } else {
        getSkillsPage(data);
    }
}
